$(function() {
    // Dependencies
    function getGlobal(variable) {
        return window[variable];
    }
    
    function $first(query, parent = document.documentElement) {
        if (!parent)
            return undefined;
        
        try {
            return parent.querySelector(query);
        }
        catch {
            return undefined;
        }
    }
    
    function $query(query_or_tagName, parent = document.documentElement) {
        if (!parent)
            return [];
        
        try {
            return Array.from(parent.querySelectorAll(query_or_tagName));
        }
        catch {
            return [];
        }
    }
    
    function $after(referenceEl, el) {
        el && referenceEl && referenceEl.insertAdjacentElement('afterend', el);
    }
    
    function $handle(el_or_els, ...nameEventHandlerPairs) {
        const handlers = [];
    
        for (const pair of nameEventHandlerPairs) {
            const [event, eventHandler] = pair;
            const [handler, options] = eventHandler instanceof Function ? [eventHandler, {}] : [eventHandler.handler, eventHandler];
            const els = el_or_els instanceof Array ? el_or_els : [el_or_els]; 
            const thisEventHandlers = [];
            
            els.forEach(el => {
                if (el) {
                    const fn = (event) => {
                        handler(event, el, event.target);
                    };
                    el.addEventListener(event, fn, options);
                    thisEventHandlers.push(fn);
                }
                else {
                    thisEventHandlers.push(null);
                }
            });
    
            handlers.push(thisEventHandlers);
        }
    
        return el_or_els instanceof Array ? handlers : handlers.map(handler => handler[0]);
    }
    
    function palpableElements() {
        const selectors = new Set();
        selectors.add('input');
        selectors.add('select');
        selectors.add('textarea');
    
        const palpableElements = getGlobal('palpableElements');
        if (palpableElements instanceof Array) {
            for (const selector of palpableElements) {
                if (typeof selector == 'string') {
                    selectors.add(selector);
                }
            }
        }
    
        return Array.from(selectors).join(',');
    }
    
    function $serialize(el_or_els, serializer) {
        if (!el_or_els) {
            return null;
        }
    
        if (el_or_els instanceof Array) {
            return el_or_els.reduce((result, el) => {
                const serializedInput = $serialize(el, serializer);
                if (serializedInput && serializedInput.length > 0) {
                    result.push(serializedInput);
                }
                return result;
            }, []).join('&');
        }
        else {
            const serializableElement = (() => {
                const isSerializable =
                    ('name' in el_or_els && typeof el_or_els.name == 'string') &&
                    ('value' in el_or_els && (
                        typeof el_or_els.value == 'string' ||
                        typeof el_or_els.value == 'number' ||
                        typeof el_or_els.value == 'boolean'
                    ));
                return isSerializable ? el_or_els : null;
            })();
            if (el_or_els instanceof HTMLInputElement || el_or_els instanceof HTMLSelectElement || el_or_els instanceof HTMLTextAreaElement) {
                if (!el_or_els.disabled && el_or_els.name.length > 0) {
                    if (serializer) {
                        return serializer(el_or_els);
                    }
                    else {
                        if ((el_or_els.type == 'checkbox' || el_or_els.type == 'radio') && !(el_or_els).checked) {
                            return '';
                        }
                        else {
                            return `${encodeURIComponent(el_or_els.name)}=${encodeURIComponent(el_or_els.value)}`;
                        }
                    }
                }
                else {
                    return '';
                }
            }
            else if (serializableElement) {
                if (serializer) {
                    return serializer(serializableElement);
                }
                else {
                    return `${encodeURIComponent(serializableElement.name)}=${encodeURIComponent(serializableElement.value)}`;
                }
            }
            else {
                const fn = serializer ?? $serialize;
                return $query(palpableElements(), el_or_els).reduce((result, input) => {
                    const serializedInput = fn(input);
                    serializedInput && result.push(serializedInput);
                    return result;
                }, []).join('&');
            }
        }
    }
    
    function defaultErrorMessage(key) {
        return getGlobal('msg')[key] ?? 'missing translation!';
    }
    
    // From http://emailregex.com/
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    
    const defaultValidationRules = {
        required: {
            validator: el => {
                if (el.type == 'checkbox') {
                    return el.checked;
                }
                else if (el.type == 'radio') {
                    return $query(`[type="radio"][name="${el.name}"]`).some(el => el.checked);
                }
                else {
                    return el.value.length > 0 && el.value != 'null';
                }
            },
            errorMessage: defaultErrorMessage('ERR_MISSINGFIELDS')
        },
        email: {
            validator: el => (el.value.length == 0) || emailRegex.test(el.value),
            errorMessage: defaultErrorMessage('ERR_INVALIDMAIL')
        },
        equalTo: {
            validator: (el, otherSelector) => {
                const other = typeof otherSelector === 'string' ? $first(otherSelector, el.closest('form') ?? document.documentElement) : otherSelector;
                return !other || el.value == other.value;
            },
            errorMessage: defaultErrorMessage('MSG_PASSWORD_NOT_EQUAL')
        },
        complexPassword: {
            validator: el => el.value.length >= 8 && /[a-z]/g.test(el.value) && /[A-Z]/g.test(el.value) && /[0-9]/g.test(el.value),
            errorMessage: defaultErrorMessage('MSG_PASSWORD_NOT_COMPLEX')
        },
        dateFrom: {
            errorMessage: (el, arg) => {
                const minDateString = (typeof arg === 'string' && arg.length > 0)
                    ? (arg ?? el.min)
                    : el.min;
                const minDate = new Date(minDateString);
                return defaultErrorMessage('ERR_DATE_FROM').replace(':from', minDate.toLocaleDateString());
            },
            validator: (el, arg) => {
                if (el.value.length == 0) {
                    return true;
                }
    
                const date = new Date(el.value);
    
                const minDateString = (typeof arg === 'string' && arg.length > 0)
                    ? (arg ?? el.min)
                    : el.min;
                const minDate = new Date(minDateString);
    
                return date >= minDate;
            }
        },
        dateTo: {
            errorMessage: (el, arg) => {
                const maxDateString = (typeof arg === 'string' && arg.length > 0)
                    ? (arg ?? el.max)
                    : el.max;
                const maxDate = new Date(maxDateString);
                return defaultErrorMessage('ERR_DATE_TO').replace(':to', maxDate.toLocaleDateString());
            },
            validator: (el, arg) => {
                if (el.value.length == 0) {
                    return true;
                }
    
                const date = new Date(el.value);
    
                const maxDateString = (typeof arg === 'string' && arg.length > 0)
                    ? (arg ?? el.max)
                    : el.max;
                const maxDate = new Date(maxDateString);
    
                return date <= maxDate;
            }
        },
        dateFromTo: {
            errorMessage: (el, arg) => {
                let minDate, maxDate;
                try {
                    const [minDateString, maxDateString] = JSON.parse(arg);
                    minDate = new Date(minDateString);
                    maxDate = new Date(maxDateString);
                }
                catch {
                    minDate = new Date(el.min);
                    maxDate = new Date(el.max);
                }
                return defaultErrorMessage('ERR_DATE_FROM_TO')
                    .replace(':from', minDate.toLocaleDateString())
                    .replace(':to', maxDate.toLocaleDateString());
            },
            validator: (el, arg) => {
                if (el.value.length == 0) {
                    return true;
                }
    
                const date = new Date(el.value);
                let minDate, maxDate;
                try {
                    const [minDateString, maxDateString] = JSON.parse(arg);
                    minDate = new Date(minDateString);
                    maxDate = new Date(maxDateString);
                }
                catch {
                    minDate = new Date(el.min);
                    maxDate = new Date(el.max);
                }
    
                return date >= minDate && date <= maxDate;
            }
        },
        vatPrefix: {
            validator: el => !el.required || /^[A-Z]{2}.*$/gm.test(el.value),
            errorMessage: defaultErrorMessage('ERR_VAT_PREFIX')
        },
        vat: {
            validator: (el, countryId) => {
                const vatPatternsEU = {
                    13: /^(AT)? ?U[0-9]{9}$/,
                    20: /^(BE)? ?[0-9]{10}$/,
                    22: /^(BG)? ?[0-9]{9,10}$/,
                    53: /^(CY)? ?[0-9]{8}[A-Z]{1}$/,
                    97: /^(HR)? ?[0-9]{11}$/,
                    58: /^(DK)? ?[0-9]{8}$|^(DK)? ?[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/,
                    63: /^(EE)? ?[0-9]{9}$/,
                    69: /^(FI)? ?[0-9]{8}$/,
                    74: /^(FR)? ?[0-9A-Z]{2}[0-9]{9}$/,
                    56: /^(DE)? ?[0-9]{9}$/,
                    88: /^(EL)? ?[0-9]{9}$/,
                    99: /^(HU)? ?[0-9]{8}$/,
                    101: /^(IE)? ?[0-9]{7}[A-Z]{1,2}$/,
                    108: /^(IT)? ?[0-9]{11}$/,
                    132: /^(LV)? ?[0-9]{11}$/,
                    130: /^(LT)? ?([0-9]{9}|[0-9]{12})$/,
                    131: /^(LU)? ?[0-9]{8}$/,
                    147: /^(MT)? ?[0-9]{8}$/,
                    160: /^(NL)? ?[0-9]{9}B[0-9]{2}$/,
                    174: /^(PL)? ?[0-9]{10}$/,
                    178: /^(PT)? ?[0-9]{9}$/,
                    183: /^(RO)? ?[0-9]{2,10}$/,
                    195: /^(SK)? ?[0-9]{10}$/,
                    193: /^(SI)? ?[0-9]{8}$/,
                    67: /^(ES)? ?[0-9A-Z]{1}[0-9]{7}[0-9A-Z]{1}$/,
                    190: /^(SE)? ?[0-9]{12}$/,
                }
                const key = parseInt(countryId);
                if (key in vatPatternsEU) {
                    return !el.required || vatPatternsEU[key].test(el.value);
                }
                else {
                    return !el.required || Object.values(vatPatternsEU).some(pattern => pattern.test(el.value));
                }
            },
            errorMessage: defaultErrorMessage('ERR_VAT')
        },
        pecOrSdi: {
            validator: (el, otherSelector) => {
                const other = typeof otherSelector === 'string'
                    ? $first(otherSelector, el.closest('form') ?? document.documentElement)
                    : otherSelector;
                return other == null || other.value == null || (el.value.length > 0 || other.value.length > 0);
            },
            errorMessage: defaultErrorMessage('ERR_PEC_OR_SDI')
        },
        dateRange: {
            validator: (el, arg) => {
                let bounds;
                if (typeof arg === 'string') {
                    try {
                        bounds = JSON.parse(arg);
                    }
                    catch {
                        return true;
                    }
                }
                else {
                    bounds = arg;
                }
                
                const date = new Date(el.value);
                const [to, from] = [
                    bounds.to ? new Date(bounds.to) <= date : true,
                    bounds.from ? new Date(bounds.from) >= date : true
                ];
                return to && from;
            },
            errorMessage: (el, bounds) => {
                function toDateString(date) {
                    return new Date(date).toLocaleDateString('it_IT');
                }
                
                if (bounds.from && bounds.to) {
                    return defaultErrorMessage('ERR_DATE_FROM_TO').replace(':from', toDateString(bounds.from)).replace(':to', toDateString(bounds.to));
                }
                else if (bounds.from) {
                    return defaultErrorMessage('ERR_DATE_FROM').replace(':from', toDateString(bounds.from));
                }
                else {
                    return defaultErrorMessage('ERR_DATE_TO').replace(':to', toDateString(bounds.to));
                }
            }
        },
        zip: {
            validator: el => {
                if (!el || !el.value || el.value.length == 0) {
                    return true;
                }
    
                const countryId = $first('[name*="country_id"]:not([type="hidden"])', el.closest('form'))?.value;
                switch (countryId) {
                    // ITALY
                    case '108':
                        return /^[0-9]{5}$/g.test(el.value);    
    
                    default:
                        return true;
                }
            },
            errorMessage: defaultErrorMessage('ERR_ZIP_FORMAT')
        }
    }
    defaultValidationRules["vat_prefix"] = defaultValidationRules.vatPrefix;
    defaultValidationRules["pec_or_sdi"] = defaultValidationRules.pecOrSdi;
    defaultValidationRules["date_range"] = defaultValidationRules.dateRange;
    
    const defaultErrorPlacement = (error, el) => {
        if (el.classList.contains('no-error-label') || el.closest('.no-error-label'))
            return;
    
    
        const id = el.id;
        const type = el.type;
    
        const text = error.textContent;
        error.title =  text;
    
        if (type === 'radio') {
            $after(el.closest('.radio-container'), error);
        }
        else if (type === 'checkbox') {
            $after(el.closest('.checkbox-container'), error);
        }
        else {
            if (id.includes('cel')) {
                $after(el.parentElement, error);
            }
            else if ($first('.password-eye', el.parentElement)) {
                $after(el.parentElement, error);
            }
            else {
                $after(el, error);
            }
        }
    };
    
    function $validation(el_or_els, options) {
        function validate(el) {
            let errorCount = 0;
            let invalidElements = [];
    
            $query(palpableElements(), el).forEach(input => {
                const errors = validateElement(input, el);
                if (errors > 0) {
                    errorCount += errors;
                    invalidElements.push(input);
                }
            });
    
            if (errorCount > 0 && options?.scrollToError) {
                const hash = $first('input.error, select.error, textarea.error', el)?.id;
                if (hash) {
                    const oldURL = window.location.href;
                    window.location.hash = hash;
                    window.dispatchEvent(new HashChangeEvent('hashchange', {
                        oldURL: oldURL,
                        newURL: window.location.href
                    }));
                }
            }
    
            return {
                errorCount: errorCount,
                invalidElements: invalidElements,
                valid: errorCount == 0
            };
        }
    
        function validateElement(input, parent) {
            const globalValidationRules = {
                ...defaultValidationRules,
                ...(getGlobal('customValidationRules') ?? {})
            };
    
            const validationRules = {
                ...$getRules(input),
                ...((options?.rules ?? {})[input.name] ?? {})
            };
    
            input.classList.remove('error');
            $query(`label[for="${input.id}"].error`, parent).forEach(label => label.remove());
    
            if (input.disabled) {
                return 0;
            }
    
            for (const [rule, value] of Object.entries(validationRules)) {
                const validator = !(typeof value === 'boolean' || typeof value === 'string') ? value : globalValidationRules[rule];
    
                if (validator) {
                    if (!validator.validator(input, value)) {
                        const error = document.createElement('label');
                        error.classList.add('error');
                        error.setAttribute("for", input.id);
                        error.textContent = typeof validator.errorMessage === 'string' ? validator.errorMessage : validator.errorMessage(input, value);
                        (validator.errorPlacement ?? options?.errorPlacement ?? defaultErrorPlacement)(error, input);
                        input.classList.add('error');
                        return 1;
                    }
                }
            }
            return 0;
        }
    
        const els = el_or_els instanceof Array ? el_or_els : [el_or_els];
    
        $handle(els,
            ['submit', (event, target) => {
                event.preventDefault();
                event.stopImmediatePropagation();
                const {errorCount, invalidElements} = validate(target);
                if (errorCount > 0) {
                    (options?.errorHandler ?? ((_0, _1, invalidElements) => { invalidElements[0].focus(); }))(target, errorCount, invalidElements);
                }
                else {
                    (options?.successHandler ?? (() => {}))(target);
                }
            }]
        );
    
        els.forEach(el => {
            if (el) {
                $handle(el,
                    ['change', (_0, _1, input) => validateElement(input, el)],
                    ['focusout', (_0, _1, input) => validateElement(input, el)]
                )
                el.setAttribute('novalidate', '');
            }
        });
    
        return validate;
    }
    
    function $getRules(el) {
        const customValidationRules = getGlobal('customValidationRules') ?? {};
        const rules = {};
    
        if (el.required) {
            rules.required = true;
        }
    
        if (el instanceof HTMLInputElement) {
            if (el.type == 'email') {
                rules.email = true;
            }
        
            if (el.type == 'date') {
                if (el.min && el.max) {
                    rules.dateFromTo = true;
                }
                else if (el.min) {
                    rules.dateFrom = true;
                }
                else if (el.max) {
                    rules.dateTo = true;
                }
            }
        }
    
        for (const [key, value] of Object.entries(el.dataset)) {
            if (key.startsWith('rule')) {
                const ruleName = key.slice(4, 5).toLowerCase() + key.slice(5);
                if (ruleName in defaultValidationRules || ruleName in customValidationRules) {
                    rules[ruleName] = value;
                }
                else {
                    const hyphenedRuleName = ruleName.replace(/[a-z][A-Z]/g, str => `${str[0]}-${str[1].toLowerCase()}`);
                    if (hyphenedRuleName in defaultValidationRules || hyphenedRuleName in customValidationRules) {
                        rules[hyphenedRuleName] = value;
                    }
                }
            }
        }
    
        return rules;
    }
    
    // RewixForm
    function parseFunctions(value, defaultFunction) {
        return (value ?? "").split(",").reduce((handlers, handler) => {
            handler = handler.trim();
    
            const fn = getGlobal(handler);
            if (fn instanceof Function) {
                handlers.push(fn);
            }
            else if (handler === "default") {
                handlers.push(defaultFunction);
            }
    
            return handlers;
        }, []);
    }
    class RewixForm extends HTMLFormElement {
        _validator;
        _timestamp;
        _interactableElements = [];
    
        static event = {
            beforeSubmit: "rewix-form:before-submit",
            afterSubmit: "rewix-form:after-submit",
            success: "rewix-form:success",
            failure: "rewix-form:failure"
        }
    
        constructor() {
            super();
            
            this._validator = $validation(this, {
                successHandler: () => this.onValid(),
                errorHandler: (_, errorCount, invalidElements) => this.onInvalid(errorCount, invalidElements),
                scrollToError: true
            });
    
            for (const eventName of this.submitOn) {
                this.addEventListener(eventName, () => {
                    if (this.requestSubmit) {
                        this.requestSubmit();
                    }
                    else {
                        this.dispatchEvent(new SubmitEvent('submit', {
                            cancelable: true,
                            composed: true,
                            submitter: this
                        }));
                    }
                });
            }
        }
    
        static canonicalName = "rewix-form";
        static componentName = RewixForm.canonicalName;
        static init(name = RewixForm.canonicalName) {
            if (!window.customElements.get(name)) {
                window.customElements.define(name, RewixForm, { extends: "form" });
                RewixForm.componentName = name;
            }
        }
    
        get requireConfirm() {
            return typeof this.dataset.requireConfirm !== "undefined";
        }
        set requireConfirm(value) {
            if (value) {
                this.dataset.requireConfirm = "";
            }
            else {
                delete this.dataset.requireConfirm;
            }
        }
    
        get submitOn() {
            return (this.dataset.submitOn ?? "").split(",").map(eventName => eventName.trim());
        }
    
        get submitDelay() {
            return parseInt(this.dataset.submitDelay ?? "0") || 0;
        }
        set submitDelay(value) {
            this.dataset.submitDelay = value.toString();
        }
    
        get submitting() {
            return typeof this.dataset.submitting !== "undefined";
        }
        set submitting(value) {
            if (value) {
                this.dataset.submitting = "";
                this._disableInteractableElements();
            }
            else {
                this._enableInteractableElements();
                delete this.dataset.submitting;
            }
        }
    
        _performSubmit = async (checkRequireConfirm = true) => {
            if (checkRequireConfirm && this.requireConfirm) {
                showQuestionBox(
                    msg[this.dataset.confirmMsg] ?? msg["MSG_GENERIC_CONFIRM"] ?? this.dataset.confirmMsg ??  "",
                    () => this._performSubmit(false)
                );
            }
            else {
                this.submitting = true;
                this.beforeSubmit();
                this.dispatchEvent(new CustomEvent(RewixForm.event.beforeSubmit, {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                    detail: this
                }));
    
                let serializer = $serialize;
                if (getGlobal(this.dataset.serializer) instanceof Function) {
                    serializer = getGlobal(this.dataset.serializer);
                }
    
                let headers = {};
                if (this.dataset.headers) {
                    const headersFunction = getGlobal(this.dataset.headers);
                    if (headersFunction instanceof Function) {
                        headers = headersFunction()
                    }
                    else {
                        try {
                            headers = JSON.parse(this.dataset.headers);
                        }
                        catch {
                            headers = {}
                        }
                    }
    
                    if (typeof headers !== "object") {
                        headers = {}
                    }
                }
    
                const response = await fetch(this.action, {
                    method: this.method,
                    body: serializer(this),
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                        ...headers
                    }
                });
    
                if (response.ok) {
                    if (response.redirected) {
                        this.onRedirect(response.url);
                    }
                    else {
                        const data = await response.json();
                        this.onSuccess(data);
                        this.dispatchEvent(new CustomEvent(RewixForm.event.success, {
                            bubbles: true,
                            cancelable: true,
                            composed: true,
                            detail: [this, data]
                        }));
                    }
                }
                else {
                    this.onFailure();
                    this.dispatchEvent(new CustomEvent(RewixForm.event.failure, {
                        bubbles: true,
                        cancelable: true,
                        composed: true,
                        detail: this
                    }));
                }
    
                if (!(response.ok && response.redirected)) {
                    this.submitting = false;
                    this.afterSubmit();
                    this.dispatchEvent(new CustomEvent(RewixForm.event.afterSubmit, {
                        bubbles: true,
                        cancelable: true,
                        composed: true,
                        detail: this
                    }));
                }
            }
        };
    
        submit() {
            if (this.submitting) {
                return;
            }
    
            if (this.submitDelay > 0) {
                const timestamp = Date.now();
                this._timestamp = timestamp;
                window.setTimeout(() => {
                    if (timestamp == this._timestamp) {
                        this._performSubmit();
                    }
                }, this.submitDelay);
            }
            else {
                this._performSubmit();
            }
        }
    
        async submitAsync() {
            if (this.submitting) {
                return;
            }
    
            if (this.submitDelay > 0) {
                const timestamp = Date.now();
                this._timestamp = timestamp;
                await new Promise(resolve => {
                    window.setTimeout(async () => {
                        if (timestamp == this._timestamp) {
                            await this._performSubmit();
                        }
                        resolve();
                    }, this.submitDelay);
                });
            }
            else {
                await this._performSubmit();
            }
        }
    
        onValid() {
            const handlers = parseFunctions(this.dataset.onValid, RewixForm.defaultOnValid);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this);
                }
            }
            else {
                RewixForm.defaultOnValid(this);
            }
        }
    
        onInvalid(errorCount, invalidElements) {
            const handlers = parseFunctions(this.dataset.onInvalid, RewixForm.defaultOnInvalid);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this, errorCount, invalidElements);
                }
            }
            else {
                RewixForm.defaultOnInvalid(this, errorCount, invalidElements);
            }
        }
    
        beforeSubmit() {
            const handlers = parseFunctions(this.dataset.beforeSubmit, RewixForm.defaultBeforeSubmit);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this);
                }
            }
            else {
                RewixForm.defaultBeforeSubmit(this);
            }
        }
    
        afterSubmit() {
            const handlers = parseFunctions(this.dataset.afterSubmit, RewixForm.defaultAfterSubmit);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this);
                }
            }
            else {
                RewixForm.defaultAfterSubmit(this);
            }
        }
    
        onSuccess(data) {
            const handlers = parseFunctions(this.dataset.onSuccess, RewixForm.defaultOnSuccess);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this, data);
                }
            }
            else {
                RewixForm.defaultOnSuccess(this, data);
            }
        }
    
        onFailure() {
            const handlers = parseFunctions(this.dataset.onFailure, RewixForm.defaultOnFailure);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this);
                }
            }
            else {
                RewixForm.defaultOnFailure(this);
            }
        }
    
        onRedirect(url) {
            const handlers = parseFunctions(this.dataset.onRedirect, RewixForm.defaultOnRedirect);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this, url);
                }
            }
            else {
                RewixForm.defaultOnRedirect(this, url);
            }
        }
    
        onSuccessfulResponse(data) {
            const handlers = parseFunctions(this.dataset.onSuccessfulResponse, RewixForm.defaultOnSuccessfulResponse);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this, data);
                }
            }
            else {
                RewixForm.defaultOnSuccessfulResponse(this, data);
            }
        }
    
        onErrorResponse(data) {
            const handlers = parseFunctions(this.dataset.onErrorResponse, RewixForm.defaultOnErrorResponse);
            if (handlers.length > 0) {
                for (const handler of handlers) {
                    handler(this, data);
                }
            }
            else {
                RewixForm.defaultOnErrorResponse(this, data);
            }
        }
    
        validate() {
            return this._validator(this);
        }
    
        isValid() {
            return this._validator(this).valid;
        }
    
        _disableInteractableElements() {
            if (!this._interactableElements) {
                this._interactableElements = [];
            }
    
            if (this._interactableElements.length > 0) {
                return;
            }
    
            for (const element of Array.from(this.querySelectorAll("input, option, textarea, button"))) {
                const isInteractable = !(element.disabled || ("readOnly" in element && element.readOnly));
                if (isInteractable) {
                    this._interactableElements.push(element);
                    if ("readOnly" in element) {
                        element.readOnly = true;
                    }
                    else {
                        element.disabled = true;
                    }
                }
            }
        }
        _enableInteractableElements() {
            for (const element of this._interactableElements) {
                if ("readOnly" in element) {
                    element.readOnly = false;
                }
                else {
                    element.disabled = false;
                }
            }
    
            this._interactableElements = [];
        }
    
        static isResponseSuccessful(data) {
            if ("success" in data) {
                return data.success;
            }
            else if ("status" in data) {
                return data.status;
            }
            else {
                return false;
            }
        }
    
        static getErrorMessage(data) {
            let key;
            
            if ("errors" in data) {
                key = data?.errors?.message;
            }
            else if ("code" in data && typeof data.code === "string") {
                key = data.code;
            }
            else if ("message" in data) {
                key = data.message;
            }
    
            return msg[key] ?? (msg["MSG_GENERIC_ERROR_WITH_CODE"] ? (msg["MSG_GENERIC_ERROR_WITH_CODE"] + key) : null) ?? msg["MSG_GENERIC_ERROR"];
        }
    
        static defaultOnValid(form) {
            form.submit();
        }
    
        static defaultOnInvalid(form, errorCount, invalidElements) {
            return;
        }
    
        static defaultBeforeSubmit(form) {
            form.classList.add("loading");
        }
    
        static defaultAfterSubmit(form) {
            form.classList.remove("loading");
        }
    
        static defaultOnSuccess(form, data) {
            if (RewixForm.isResponseSuccessful(data)) {
                form.onSuccessfulResponse(data);
            }
            else {
                form.onErrorResponse(data);
            }
        }
    
        static defaultOnSuccessfulResponse(form, data) {
            const callback = (typeof form.dataset.nexturl !== "undefined") ? (() => {
                try {
                    const url = new URL(form.dataset.nexturl, window.location.origin);
                    window.location.href = url.toString();
                }
                catch {
                    return;
                }
            }) : null;
    
            if (typeof form.dataset.successMsg !== "undefined") {
                showMessageBox("success", msg[form.dataset.successMsg] ?? form.dataset.successMsg ?? "", callback);
            }
            else if (callback) {
                callback();
            }
        }
    
        static defaultOnErrorResponse(form, data) {
            showMessageBox("danger", RewixForm.getErrorMessage(data));
        }
    
        static defaultOnFailure(form) {
            showMessageBox("danger", msg[form.dataset.failureMsg] ?? form.dataset.failureMsg ?? msg["MSG_GENERIC_ERROR"]);
        }
    
        static defaultOnRedirect(form, url) {
            window.location.href = url;
        }
    }
    
    // Init
    RewixForm.init();

    window["serializeWithNonce"] = (form) => {
        return [$serialize(form), `nonce=${Date.now()}`].join("&");
    }
})