$(document).ready(function() {
    $('.datepicker').pickadate()
    $('.timepicker').pickatime()

    let number = 0;

    $('#sortpicture1').change(function() {
        const file_data = $('#sortpicture1').prop('files')[0];   
        const form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-1').addClass('hide');
                    $('#image-uploaded-1').removeClass('hide');
                    $('#link-image1').val(JSON.parse(php_script_response).link);
                    number++;
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#sortpicture2').change(function() {
        const file_data = $('#sortpicture1').prop('files')[0];   
        const form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-2').addClass('hide');
                    $('#image-uploaded-2').removeClass('hide');
                    $('#link-image2').val(JSON.parse(php_script_response).link);
                    number++
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#sortpicture3').change(function() {
        const file_data = $('#sortpicture1').prop('files')[0];   
        const form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-3').addClass('hide');
                    $('#image-uploaded-3').removeClass('hide');
                    $('#link-image3').val(JSON.parse(php_script_response).link);
                    number++
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#sortpicture4').change(function() {
        const file_data = $('#sortpicture1').prop('files')[0];   
        const form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-4').addClass('hide');
                    $('#image-uploaded-4').removeClass('hide');
                    $('#link-image4').val(JSON.parse(php_script_response).link);
                    number++
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#sortpicture5').change(function() {
        const file_data = $('#sortpicture1').prop('files')[0];   
        const form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-5').addClass('hide');
                    $('#image-uploaded-5').removeClass('hide');
                    $('#link-image5').val(JSON.parse(php_script_response).link);
                    number++
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
    });

    $('#show-private-contact').on('click', function () {
        $('.form_designer').attr('style','opacity:1;transition:1s;')
        $('#business-contact').addClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-send').addClass('hide');
        $('#private-contact').removeClass('hide');
        $('#private-contact-personal').removeClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-send').addClass('hide');

    });

    $('.close-contact-form').on('click', function () {
        $('.form_designer').attr('style','opacity:0;transition:1s;')
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-send').addClass('hide');
    });

    $('#to-budget-private').on('click', function () {
        to_budget_private();
    });

    $('#back-personal-private').on('click', function () {
        to_personal_private();
    });

    $('#back-budget-private').on('click', function () {
        to_budget_private();
    });

    $('#to-description-private').on('click', function () {
        to_description_private();
    });

    $('#to-send-private').on('click', function () {
        to_send_private(number);
    });

    $('.private-personal').on('click', function () {
        to_personal_private();
    });

    $('.private-description').on('click', function () {
        to_description_private();
    });

    $('.private-budget').on('click', function () {
        to_budget_private();
    });

    $('.private-send').on('click', function () {
        to_send_private(number);
    });

    $('#show-business-contact').on('click', function () {
        $('.form_designer').attr('style','opacity:1;transition:1s;')
        $('#business-contact').removeClass('hide');
        $('#business-contact-personal').removeClass('hide');
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-send').addClass('hide');
        $('#private-contact').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-send').addClass('hide');
    });

    $('.close-contact-form').on('click', function () {
        $('.form_designer').attr('style','opacity:0;transition:1s;')
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-send').addClass('hide');
    });

    $('#to-budget-business').on('click', function () {
        to_budget_business();
    });

    $('#back-personal-business').on('click', function () {
        to_personal_business();
    });

    $('#to-send-business').on('click', function () {
        to_send_business();
    });

    $('.business-personal').on('click', function () {
        to_personal_business();
    });

    $('.business-budget').on('click', function () {
        to_budget_business();
    });

    $('.business-send').on('click', function () {
        to_send_business();
    });

    const url = new URL(window.location.href);
    if (url.searchParams.has('open')) {
        const modal = $(url.searchParams.get('open'));
        if (modal.length > 0) {
            modal.foundation('open');
        }
    }
});

function to_personal_private() {
    $('#private-contact-budget').addClass('hide');
    $('#private-contact-personal').removeClass('hide');
    $('#private-contact-send').addClass('hide');
    $('#private-contact-space-description').addClass('hide');
}

function to_budget_private() {
    const form = $("#private-contact").get(0);
    if (form && form._validator($("#private-contact-personal")[0]).valid) {
        $('#private-contact-budget').removeClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
    }
}

function to_description_private() {
    const form = $("#private-contact").get(0);
    if (form && form._validator($("#private-contact-send")[0]).valid) {
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').removeClass('hide');
    }
}

function to_send_private(number) {
    const form = $("#private-contact").get(0);
    if (form && form._validator($("#private-contact-budget")[0]).valid) {
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-send').removeClass('hide');

        document.getElementById('private-contact-name-lastname').innerHTML = $('#private-name').val();
        document.getElementById('private-contact-email').innerHTML = $('#private-email').val();
        document.getElementById('private-contact-phone').innerHTML = $('#private-phone').val();
        document.getElementById('private-contact-city').innerHTML = $('#private-city').val();

        document.getElementById('private-contact-description').innerHTML = findCheckedInput('rinnova-private');

        document.getElementById('private-contact-colors').innerHTML = $('#private-space-colors option:checked').text().trim();
        document.getElementById('private-contact-budget-input').innerHTML = findCheckedInput('private-budget');

        if (number != 0) {
            $('#number-count').html($('#number-count').html() + number);
        }
        else {
            $('#private-image-div').addClass('hide');
        }
    }
}

function findCheckedInput(name) {
    let result = '';
    const x = document.getElementsByName(name);
    for (let i = 0; i < x.length; i++) {
        if (x[i].checked) {
            result = result.concat(x[i].nextElementSibling.textContent.trim()) + ' ';
        }
    }
    return result;
}

function to_budget_business() {
    const form = $("#business-contact").get(0);
    if (form && form._validator($("#business-contact-personal")[0]).valid) {
        $('#business-contact-budget').removeClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-send').addClass('hide');
    }
}

function to_send_business() {
    const form = $("#business-contact").get(0);
    if (form && form._validator($("#business-contact-budget")[0]).valid) {
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-send').removeClass('hide');

        document.getElementById('business-contact-name-lastname').innerHTML = $('#business-name').val();
        document.getElementById('business-contact-email').innerHTML = $('#business-email').val();
        document.getElementById('business-contact-phone').innerHTML = $('#business-phone').val();
        document.getElementById('business-contact-description').innerHTML = findCheckedInput('rinnova-business');

        document.getElementById('business-contact-colors').innerHTML = $('#business-space-colors option:checked').text().trim();
        document.getElementById('business-contact-budget-input').innerHTML = findCheckedInput('business-budget');
    }
}

function contactRecaptchaCallbackPrivate() {
    const form = $("#private-contact").get(0);
    if (form && form.isValid()) {
        form.submit();
    }
    grecaptcha.reset();
}

function contactRecaptchaCallbackBusiness() {
    const form = $("#business-contact").get(0);
    if (form && form.isValid()) {
        form.submit();
    }
    grecaptcha.reset();
}

function contactFormRecaptcha() {
    const form = $('#contact-form, #contact-form-exhibition').get(0);
    if (form && form.isValid()) {
        form.submit();
    }
    grecaptcha.reset();
}